// TODO use one login type
export enum ELoginType {
    KEEPER = 'keeper',
    EXCHANGE = 'exchange',
    METAMASK = 'metamask',
}

// TODO use one login type
export enum LoginType {
    SEED,
    EMAIL,
    METAMASK,
}
